import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import { State } from 'state/store';
import { BaseState } from 'modules/fleet-management/state/types/outlineTypes';
import { DateTimeConverter } from 'utils/functions/dateUtil';
import { AircraftDto } from '../types/AircraftTypes';

export interface FleetsState extends BaseState {
  fleetList: AircraftDto[];
}

const initialState: FleetsState = {
  fleetList: [],
  error: undefined,
};

export const fetchFleets = createAsyncThunk('fleets/fetchFleets', async () => {
  const response = await services.getFleets();
  return response.data as AircraftDto[];
});

export const fleetsSlice = createSlice({
  name: 'fleets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFleets.pending, (state) => {
        state.error = undefined;
      })
      .addCase(fetchFleets.fulfilled, (state, action) => {
        state.error = undefined;
        state.fleetList = action.payload;
      })
      .addCase(fetchFleets.rejected, (state, payload) => {
        state.error = payload.error.message;
        state.fleetList = [];
      });
  },
});

export const selectFleets = (state: State) =>
  state.fleets.fleetList.map((aircraft) => {
    return { ...aircraft, lastContact: new DateTimeConverter(aircraft.lastContact).convertToUtc().formatToTableFormat() };
  });

export default fleetsSlice.reducer;
