import { CanvasItem, isSlotItem, ItemHealthStatus } from 'modules/fleet-management/state/types/outlineTypes';

const hasHealthIssue = (item?: CanvasItem) => {
  if (!item || isSlotItem(item)) {
    return false;
  }

  return healthIssuePredicateFn(item);
};

const healthIssuePredicateFn = (item: CanvasItem): boolean =>
  item.health === ItemHealthStatus.INOPERATIONAL || item.health === ItemHealthStatus.PARTLY_OPERATIONAL;

const hasItemGroupHealthIssue = (items: CanvasItem[]): boolean => {
  return items.some(healthIssuePredicateFn);
};

export { hasItemGroupHealthIssue, hasHealthIssue };
