import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import store from 'state/store';
import { sortArrayByCreatedAt } from 'utils/functions/dateUtil';
import { ConfigurationItemType } from '../types/ConfigurationItemType';
import { EventType } from '../types/EventTypes';
import {
  PaginatedRequestType,
  PaginationListResponseType,
  PaginationListType,
  pagebleInitialState,
  paginationInitialState,
} from '../types/PaginationListType';

const configurationItemsAdapter = createEntityAdapter<ConfigurationItemType>({
  selectId: (item: ConfigurationItemType) => item.id,
});

const eventItemsAdapter = createEntityAdapter<EventType>({
  selectId: (item: EventType) => item.id,
});

export type ConfigurationItemsState = {
  items: EntityState<ConfigurationItemType>;
  selectedItems: ConfigurationItemType[];
  pagination: PaginationListType;
  detailedConfigurationItem: {
    manifest: string;
    typeDefinition: string;
    events: EntityState<EventType>;
  };
  loading: boolean;
  error?: Error;
};

export const initialState: ConfigurationItemsState = {
  items: configurationItemsAdapter.getInitialState(),
  selectedItems: [],
  pagination: paginationInitialState,
  detailedConfigurationItem: {
    manifest: '',
    typeDefinition: '',
    events: eventItemsAdapter.getInitialState(),
  },
  loading: false,
  error: undefined,
};

const orderResponse = (data: PaginationListResponseType) => ({
  items: (data.content as ConfigurationItemType[]).map((config) => ({
    ...config,
    checkbox: false,
  })),
  pagination: {
    ...data,
    content: [],
  },
});

export const fetchConfigurationItemsFromStart = createAsyncThunk('IC/fetchConfigurationItemsFromStart', async (searchTerm: string) => {
  const response = await services.getConfigurationItems({ searchTerm, ...pagebleInitialState });
  return orderResponse(response.data as PaginationListResponseType);
});

export const fetchConfigurationItemsNextPage = createAsyncThunk(
  'IC/fetchConfigurationItemsNextPage',
  async (request: PaginatedRequestType) => {
    const response = await services.getConfigurationItems({ searchTerm: request.searchTerm, ...request.pageable });
    return orderResponse(response.data as PaginationListResponseType);
  }
);

export const fetchConfigurationItemManifest = createAsyncThunk('IC/fetchConfigurationItemManifest', async (configItemId: string) => {
  return await services.getConfigurationItemManifest({ configItemId: configItemId });
});

export const fetchConfigurationItemTypeDefinition = createAsyncThunk(
  'IC/fetchConfigurationItemTypeDefinition',
  async (configItemId: string) => await services.getConfigurationItemTypeDefinition({ configItemId: configItemId })
);

export const fetchConfigurationItemEvents = createAsyncThunk('IC/fetchConfigurationItemEvents', async (configItemId: string) => {
  const response = await services.getConfigurationItemEvents({ configItemId: configItemId });
  return response.data as EventType[];
});

const markAsSelectedAlready = (fetchedArray: ConfigurationItemType[], selectionArray: ConfigurationItemType[]) =>
  fetchedArray.map((f) => ({ ...f, checkbox: !!selectionArray.find((s) => s.id === f.id) }));

const configurationItemsSlice = createSlice({
  name: 'IC/configurationItems',
  initialState,
  reducers: {
    toggleSelection(state, action) {
      const item = action.payload as ConfigurationItemType;
      configurationItemsAdapter.setOne(state.items, item);
      state.selectedItems = item.checkbox ? [...state.selectedItems, item] : [...state.selectedItems.filter((c) => c.id !== item.id)];
    },
    clearSelection(state, action) {
      configurationItemsAdapter.setMany(state.items, action.payload);
      state.selectedItems = [];
    },
  },
  extraReducers: (builder) =>
    builder
      // Initial loadings
      .addCase(fetchConfigurationItemsFromStart.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        configurationItemsAdapter.removeAll(state.items);
      })
      .addCase(fetchConfigurationItemsFromStart.fulfilled, (state, action) => {
        state.error = undefined;
        const items = markAsSelectedAlready(action.payload.items, state.selectedItems);
        configurationItemsAdapter.setAll(state.items, sortArrayByCreatedAt(items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchConfigurationItemsFromStart.rejected, (state, payload) => {
        configurationItemsAdapter.removeAll(state.items);
        state.error = payload.error as Error;
        state.loading = false;
      })
      // Next page loadings
      .addCase(fetchConfigurationItemsNextPage.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchConfigurationItemsNextPage.fulfilled, (state, action) => {
        state.error = undefined;
        const items = markAsSelectedAlready(action.payload.items, state.selectedItems);
        configurationItemsAdapter.addMany(state.items, sortArrayByCreatedAt(items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchConfigurationItemsNextPage.rejected, (state, payload) => {
        state.error = payload.error as Error;
        state.loading = false;
      })
      // Configuration Item Events
      .addCase(fetchConfigurationItemEvents.pending, (state) => {
        state.error = undefined;
        eventItemsAdapter.removeAll(state.detailedConfigurationItem.events);
      })
      .addCase(fetchConfigurationItemEvents.fulfilled, (state, action) => {
        state.error = undefined;
        eventItemsAdapter.setAll(state.detailedConfigurationItem.events, sortArrayByCreatedAt(action.payload));
      })
      .addCase(fetchConfigurationItemEvents.rejected, (state, payload) => {
        state.error = payload.error as Error;
        eventItemsAdapter.removeAll(state.detailedConfigurationItem.events);
      })
      // Manifest and Type definition
      .addCase(fetchConfigurationItemManifest.fulfilled, (state, action) => {
        state.error = undefined;
        const result = action.payload.data as { manifestYamlBase64: string };
        state.detailedConfigurationItem.manifest = result.manifestYamlBase64;
      })
      .addCase(fetchConfigurationItemManifest.rejected, (state, payload) => {
        state.error = payload.error as Error;
      })
      .addCase(fetchConfigurationItemTypeDefinition.fulfilled, (state, action) => {
        state.error = undefined;
        const result = action.payload.data as { typeDefinitionYamlBase64: string };
        state.detailedConfigurationItem.typeDefinition = result.typeDefinitionYamlBase64;
      })
      .addCase(fetchConfigurationItemTypeDefinition.rejected, (state, payload) => {
        state.error = payload.error as Error;
      }),
});

type RootState = ReturnType<typeof store.getState>;

export const { toggleSelection, clearSelection } = configurationItemsSlice.actions;

const configurationItemsEntitySelectors = configurationItemsAdapter.getSelectors<RootState>((state) => state.configurationItems.items);

export const selectConfigurationItems = configurationItemsEntitySelectors.selectAll;

// SELECTED ITEMS
export const selectChosenConfigurationItems = (state: RootState): ConfigurationItemType[] => state.configurationItems.selectedItems;

// LOADING
export const selectConfigurationItemsLoadingStatus = (state: RootState): boolean => state.configurationItems.loading;

// MANIFEST and TYPE-DEFINITION
export const selectConfigurationItemManifest = (state: RootState): string => state.configurationItems.detailedConfigurationItem.manifest;
export const selectConfigurationItemTypeDefinition = (state: RootState): string =>
  state.configurationItems.detailedConfigurationItem.typeDefinition;

// EVENTS
const configurationItemEventsEntitySelectors = eventItemsAdapter.getSelectors<RootState>(
  (state) => state.configurationItems.detailedConfigurationItem.events
);
export const selectConfigurationItemEvents = configurationItemEventsEntitySelectors.selectAll;

// PAGINATION
export const selectConfigurationItemsPaginationStatus = (state: RootState) => state.configurationItems.pagination;

export default configurationItemsSlice.reducer;
