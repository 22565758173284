import { HardwareItemSubType, ItemSubType } from './itemSubTypes';

export interface BaseState {
  error: string | undefined;
}

export enum DetailView {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export type Filter = {
  active: boolean;
  id: number;
  label: string;
};

export enum FilterGroupId {
  NO_FILTER = '',
  LOPA = 'slot',
  HARDWARE = 'hardware',
  SOFTWARE = 'software',
  CONTENT = 'content',
}

export type Deck = {
  id: number;
  name: string;
};

export type BoxCoordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Area = {
  id: number;
  deck: Deck;
  name: string;
  cols: number;
  rows: number;
  groupedColumns?: number[];
  coordinates?: BoxCoordinates;
};

// The values of the enum are keys for translation and for ConnectionsByType
export enum ConnectionType {
  CONNECTIONS = 'connections',
  DEPENDABLE = 'dependable',
  DEPENDED_ON = 'dependedOn',
}

export type ConnectionsByType = {
  [key in ConnectionType]?: ItemConnection[];
};

export type ItemConnection = {
  description: string;
  destination: {
    id: string;
    type: CanvasItemTypes;
    subType?: ItemSubType;
  };
  additionalData: { [key: string]: string | number };
};

export enum ConfigurationStatus {
  UP_TO_DATE = 'UP_TO_DATE',
  OUTDATED = 'OUTDATED',
}

export enum ItemHealthStatus {
  OPERATIONAL = 'Operational',
  PARTLY_OPERATIONAL = 'Partly Operational',
  INOPERATIONAL = 'Inoperational',
  UNKNOWN = 'Unknown',
}

export interface CanvasItem {
  id: number;
  itemId: string;
  gridCol: number;
  gridRow: number;
  locationPath: string; //eg.: Cabin / business / Seat 4LEFT
  itemSubType: ItemSubType;
  itemType: string;
  description?: string;
  action?: () => void;
  connectionsByType?: ConnectionsByType;
  areaId: number;
  displayName: string;
  health?: ItemHealthStatus;
}

export interface SlotItem extends CanvasItem {
  compatibleWith?: string[];
  logicalName: string;
  installed?: ItemConnection;
}

export interface HardwareItem extends CanvasItem {
  partNumber: string;
  serialNumber: string;
  configurationStatus: ConfigurationStatus;
  installedIn?: ItemConnection;
  state?: HardwareItemState;
}

export interface SoftwareItem extends CanvasItem {
  p3?: string;
}

export interface SoftwareComponentInstallation extends CanvasItem {
  partNumber: string;
  version: string;
  vendor: string;
  installationId: string;
  installationTime: string;
  events: InstallationEvent[];
  requirements: ResourceRequirements;
  certification?: SoftwareCertification;
  groups?: string[];
}

export type ContentDeploymentAsset = {
  name: string;
  type: string;
  format: string;
  path: string;
};

export interface ContentDeployment extends CanvasItem {
  partNumber: string;
  vendor: string;
  deploymentDateTime: string;
  hostId: string;
  groups?: string[];
  assets: ContentDeploymentAsset[];
  drmProtected: boolean;
  events: InstallationEvent[];
  requirements: ResourceRequirements;
}

export enum CanvasItemTypes {
  SlotItem = 'SlotItem',
  HardwareItem = 'HardwareItem',
  SoftwareItem = 'SoftwareItem',
  SoftwareComponentInstallation = 'SoftwareComponentInstallation',
  ContentDeployment = 'ContentDeployment',
}

export enum HighOrderItemTypes {
  SoftwareApp = 'SoftwareApp',
}

export const isSlotItem = (item: CanvasItem): item is SlotItem => {
  return item?.itemType === CanvasItemTypes.SlotItem;
};

export const isHardwareItem = (item: CanvasItem): item is HardwareItem => item?.itemType === CanvasItemTypes.HardwareItem;

export const isSoftwareItem = (item: CanvasItem): item is SoftwareItem => item?.itemType === CanvasItemTypes.SoftwareItem;

export const isSoftwareComponentInstallation = (item: CanvasItem): item is SoftwareComponentInstallation =>
  item?.itemType === CanvasItemTypes.SoftwareComponentInstallation;

export const isContentDeployment = (item: CanvasItem): item is ContentDeployment => item?.itemType === CanvasItemTypes.ContentDeployment;

export enum ComponentCriticality {
  HIGH = 'high',
  MID = 'mid',
}

export interface HardwareItemComponent {
  name: string;
  criticality: ComponentCriticality;
  isOperational: boolean;
  timestamp?: string;
}

export interface HardwareItemState {
  components: HardwareItemComponent[];
}

export type HardwareDTO = {
  serialNumber: string;
  partNumber: string;
  hardwareType: HardwareItemSubType;
  displayName: string;
  description: string;
  installedInItemId: string;
  state?: HardwareItemState;
};

export type HardwareItemDTO = Pick<
  HardwareItem,
  'partNumber' | 'serialNumber' | 'itemSubType' | 'displayName' | 'description' | 'installedIn' | 'state'
>;

export type InstallationEvent = {
  timestamp: string;
  level: EventSeverityLevel;
  message: string;
};

export type ResourceRequirements = {
  resources: { name: string; value: string }[];
};

export enum EventSeverityLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export enum SoftwareCertificationType {
  CERTIFIED = 'CERTIFIED',
  UNCERTIFIED = 'UNCERTIFIED',
  NONE = 'NONE',
}

export type SoftwareCertification = {
  certificateType: SoftwareCertificationType;
  certificateId: string;
  issueDate: string;
};

export enum ContentDeploymentLicenseType {
  DRM = 'DRM',
  NONE = 'NONE',
}

export type SoftwareApp = {
  name: string;
  id: string;
  components: string[];
  itemType: HighOrderItemTypes.SoftwareApp;
};

export const isSoftwareApp = (item: CanvasItem | SoftwareApp): item is SoftwareApp => item?.itemType === HighOrderItemTypes.SoftwareApp;
