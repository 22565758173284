import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import { State } from 'state/store';
import { BaseState } from 'modules/fleet-management/state/types/outlineTypes';

export type LopaDescription = {
  acManufacturer: string;
  airline: string;
  acType: string;
  acVersion: string;
  addonInfo: string;
  uuid: string;
  version: string;
  tailsign: string;
  lopaModCounter: number;
  lopaNumber: number;
};

export interface LopaDescriptionState extends BaseState {
  lopaDescription: LopaDescription | null;
}

const initialState: LopaDescriptionState = {
  lopaDescription: null,
  error: undefined,
};

export const fetchLopaDescription = createAsyncThunk('fleets/fetchLopaDescription', async (fileName: string) => {
  if (!fileName) {
    throw new Error('fetchLopaDescription can not be called with empty string for fileName');
  }
  const response = await services.getLopaDescriptionByFileName({ fileName });
  return response.data as LopaDescription;
});

export const lopaDescriptionSlice = createSlice({
  name: 'lopaDescription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLopaDescription.pending, (state) => {
        state.error = undefined;
        state.lopaDescription = null;
      })
      .addCase(fetchLopaDescription.fulfilled, (state, action) => {
        state.error = undefined;
        state.lopaDescription = action.payload;
      })
      .addCase(fetchLopaDescription.rejected, (state, payload) => {
        state.error = payload.error.message;
        state.lopaDescription = null;
      });
  },
});

export const lopaDescriptionByFileName = (state: State): LopaDescription | null => {
  return state.lopaDescription.lopaDescription;
};

export default lopaDescriptionSlice.reducer;
