import { OrganizationSelector } from '@control-tower/aerq-navigation-library';
import { usePreferencesState } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context';
import { PageTemplate } from '@control-tower/aerq-ui-library';
import { styled } from '@mui/system';
import axios from 'api/axios';
import { initServices } from 'api/services';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { KEYCLOAK_CLIENT_ID, USER_MANAGEMENT_WEB_API_URL } from 'common/environment';
import useNavigate from 'common/hooks/useNavigate';
import i18n from 'i18n';
import { SnackbarProvider } from 'notistack';
import { lazy, useEffect } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { hideLoadingOverlay } from 'utils/functions/toggleLoadingOverlay';
import HeaderTemplate from './components/Header';
import Messages from './components/Messages';
import { ROUTES } from './routes';

const INTEGRATION_CENTER_ACCESS_ROLE = 'has_integration_center_access';

const FleetView = lazy(() => import(/* webpackChunkName: 'FleetList' */ '../modules/fleet-management/view/fleets/FleetList'));
const NewAircraftView = lazy(
  () => import(/* webpackChunkName: 'NewAircraft' */ '../modules/fleet-management/view/fleets/aircraft/new/NewAircraft')
);
const AircraftOutlineView = lazy(
  () => import(/* webpackChunkName: 'AircraftOutline' */ '../modules/fleet-management/view/fleets/aircraft/outline/AircraftOutline')
);
const IntegrationCenterView = lazy(
  () => import(/* webpackChunkName: 'IntegrationCenter' */ '../modules/integration-center/view/IntegrationCenter')
);

const AppContainer = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  maxWidth: '100vw',
  flexDirection: 'column',
  '& > div': {
    flexGrow: 1,
  },
});

const StyledPageTemplate = styled(PageTemplate)`
  min-height: 100vh;
`;

const configAxios = (lang: string) => {
  const setHeader = (config: AxiosRequestConfig) => {
    return { ...config, headers: { ...config.headers, 'Accept-Language': lang } };
  };
  const handleError = (error: AxiosError) => {
    return Promise.reject(error);
  };
  axios.interceptors.request.use(setHeader, handleError);
};

const App = ({ userRoles }: { userRoles: string[] }) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const { preferences } = usePreferencesState();

  useEffect(() => {
    if (location.pathname === ROUTES.ORGANIZATION_SELECTOR) {
      hideLoadingOverlay();
    }
  }, [location.pathname]);

  useEffect(() => {
    const lang = preferences.language.toLowerCase();
    i18n.changeLanguage(lang);
    localStorage.setItem('appLanguage', lang);

    configAxios(lang);
  }, [preferences.language]);

  return (
    <AppContainer>
      <SnackbarProvider>
        <Messages />
        <StyledPageTemplate>
          <Routes>
            <Route
              path={ROUTES.ORGANIZATION_SELECTOR}
              element={
                <OrganizationSelector
                  onAfterSelectProfile={(orgInfo, keycloak) => {
                    initServices(orgInfo, keycloak);
                  }}
                  userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL}
                  notificationServiceURL=""
                  clientId={KEYCLOAK_CLIENT_ID}
                  navigateTo={navigateTo}
                  location={location}
                  Link={Link}
                />
              }
            />
            <Route
              path={ROUTES.NEW_AC}
              element={
                <HeaderTemplate>
                  <NewAircraftView />
                </HeaderTemplate>
              }
            />
            <Route
              path={ROUTES.AC_OUTLINE}
              element={
                <HeaderTemplate>
                  <AircraftOutlineView />
                </HeaderTemplate>
              }
            />
            <Route
              path={ROUTES.FLEETS}
              element={
                <HeaderTemplate>
                  <FleetView />
                </HeaderTemplate>
              }
            />
            <Route
              path={`${ROUTES.INTEGRATION_CENTER}/*`}
              element={
                <HeaderTemplate>
                  {userRoles.includes(INTEGRATION_CENTER_ACCESS_ROLE) ? <IntegrationCenterView /> : <Navigate replace to={ROUTES.FLEETS} />}
                </HeaderTemplate>
              }
            />
            <Route path="/" element={<Navigate replace to={ROUTES.FLEETS} />} />
          </Routes>
        </StyledPageTemplate>
      </SnackbarProvider>
    </AppContainer>
  );
};

export default App;
