const searchIn = (searchFor: string, searchIn: string) => {
  return searchIn.toLowerCase().includes(searchFor.toLowerCase());
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const truncateWithEllipsis = (input: string, length: number) =>
  !!length && input && input?.length > length ? `${input.substring(0, length)}...` : input;

export { searchIn, capitalizeFirstLetter, truncateWithEllipsis };
