import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import services from 'api/services';
import store from 'state/store';
import { sortArrayByCreatedAt, sortArrayByLastUpdatedAt } from 'utils/functions/dateUtil';
import { EnvironmentType } from '../types/EnvironmentType';
import { EventType } from '../types/EventTypes';
import {
  PaginatedRequestType,
  PaginationListResponseType,
  PaginationListType,
  pagebleInitialState,
  paginationInitialState,
} from '../types/PaginationListType';

const environmentsAdapter = createEntityAdapter<EnvironmentType>({
  selectId: (item: EnvironmentType) => item.id,
});

const eventItemsAdapter = createEntityAdapter<EventType>({
  selectId: (item: EventType) => item.id,
});

export type EnvironmentsState = {
  items: EntityState<EnvironmentType>;
  selectedEnvironments: EnvironmentType[];
  pagination: PaginationListType;
  detailedEnvironment: {
    events: EntityState<EventType>;
  };
  loading: boolean;
  error?: Error;
};

export const initialState: EnvironmentsState = {
  items: environmentsAdapter.getInitialState(),
  selectedEnvironments: [],
  pagination: paginationInitialState,
  detailedEnvironment: {
    events: eventItemsAdapter.getInitialState(),
  },
  loading: false,
  error: undefined,
};

const orderResponse = (data: PaginationListResponseType) => ({
  items: (data.content as EnvironmentType[]).map((config) => ({
    ...config,
    checkbox: false,
  })),
  pagination: {
    ...data,
    content: [],
  },
});

export const fetchEnvironmentsFromStart = createAsyncThunk('IC/fetchEnvironmentsFromStart', async (searchTerm: string) => {
  const response = await services.getEnvironments({ searchTerm, ...pagebleInitialState });
  return orderResponse(response.data as PaginationListResponseType);
});

export const fetchEnvironmentsNextPage = createAsyncThunk('IC/fetchEnvironmentsNextPage', async (request: PaginatedRequestType) => {
  const response = await services.getEnvironments({ searchTerm: request.searchTerm, ...request.pageable });
  return orderResponse(response.data as PaginationListResponseType);
});

export const fetchEnvironmentEvents = createAsyncThunk('IC/fetchEnvironmentEvents', async (environmentId: string) => {
  const response = await services.getEnvironmentEvents({ environmentId: environmentId });
  return response.data as EventType[];
});

const markAsSelectedAlready = (fetchedArray: EnvironmentType[], selectionArray: EnvironmentType[]) =>
  fetchedArray.map((f) => ({ ...f, checkbox: !!selectionArray.find((s) => s.id === f.id) }));

const environmentsSlice = createSlice({
  name: 'IC/environments',
  initialState,
  reducers: {
    toggleSelection(state, action) {
      const item = action.payload as EnvironmentType;
      environmentsAdapter.setOne(state.items, item);
      state.selectedEnvironments = item.checkbox
        ? [...state.selectedEnvironments, item]
        : [...state.selectedEnvironments.filter((e) => e.id !== item.id)];
    },
    clearSelection(state, action) {
      environmentsAdapter.setMany(state.items, action.payload);
      state.selectedEnvironments = [];
    },
  },
  extraReducers: (builder) =>
    builder
      // Initial loadings
      .addCase(fetchEnvironmentsFromStart.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        environmentsAdapter.removeAll(state.items);
      })
      .addCase(fetchEnvironmentsFromStart.fulfilled, (state, action) => {
        state.error = undefined;
        const items = markAsSelectedAlready(action.payload.items, state.selectedEnvironments);
        environmentsAdapter.setAll(state.items, sortArrayByLastUpdatedAt(items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchEnvironmentsFromStart.rejected, (state, payload) => {
        state.error = payload.error as Error;
        environmentsAdapter.removeAll(state.items);
        state.loading = false;
      })
      // Next page loadings
      .addCase(fetchEnvironmentsNextPage.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchEnvironmentsNextPage.fulfilled, (state, action) => {
        state.error = undefined;
        const items = markAsSelectedAlready(action.payload.items, state.selectedEnvironments);
        environmentsAdapter.addMany(state.items, sortArrayByLastUpdatedAt(items));
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchEnvironmentsNextPage.rejected, (state, payload) => {
        state.error = payload.error as Error;
        state.loading = false;
      })
      // Environment Events
      .addCase(fetchEnvironmentEvents.pending, (state) => {
        state.error = undefined;
        eventItemsAdapter.removeAll(state.detailedEnvironment.events);
      })
      .addCase(fetchEnvironmentEvents.fulfilled, (state, action) => {
        state.error = undefined;
        eventItemsAdapter.setAll(state.detailedEnvironment.events, sortArrayByCreatedAt(action.payload));
      })
      .addCase(fetchEnvironmentEvents.rejected, (state, payload) => {
        state.error = payload.error as Error;
        eventItemsAdapter.removeAll(state.detailedEnvironment.events);
      }),
});

type RootState = ReturnType<typeof store.getState>;

export const { toggleSelection, clearSelection } = environmentsSlice.actions;

const environmentsEntitySelectors = environmentsAdapter.getSelectors<RootState>((state) => state.environments.items);

export const selectEnvironments = environmentsEntitySelectors.selectAll;

// SELECTED ENVS
export const selectChosenEnvironments = (state: RootState): EnvironmentType[] => state.environments.selectedEnvironments;

// LOADING
export const selectEnvironmentsLoadingStatus = (state: RootState): boolean => state.environments.loading;

// EVENTS
const environmentEventsEntitySelectors = eventItemsAdapter.getSelectors<RootState>(
  (state) => state.environments.detailedEnvironment.events
);
export const selectEnvironmentEvents = environmentEventsEntitySelectors.selectAll;

// PAGINATION
export const selectEnvironmentsPaginationStatus = (state: RootState) => state.environments.pagination;

export default environmentsSlice.reducer;
