import { getToken } from '@control-tower/aerq-navigation-library';
import type { AxiosRequestConfig } from 'axios';
import { KeycloakInstance } from 'keycloak-js';
import axios from './axios';
import createServices, { ServiceConfig } from './provider';

export const createServiceConfig = <ServiceNames extends string = string, ParamKeys extends string = string>(
  config: Record<ServiceNames, ServiceConfig<ParamKeys>>
) => config;

const serviceConfig = createServiceConfig({
  getFleetRelatedRoles: {
    url: 'auth/roles',
    method: 'GET',
    authRequired: true,
  },
  getFleets: {
    url: 'aircrafts',
    method: 'GET',
    authRequired: true,
  },
  createAircraft: {
    url: 'aircrafts',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  getUnconfiguredAircraftConfig: {
    url: 'aircrafts/getformdata',
    method: 'GET',
    authRequired: true,
  },
  getLopaByFileName: {
    url: 'lopa/{fileName}',
    method: 'GET',
    authRequired: true,
  },
  getLopaVersionsByAircraftType: {
    url: 'lopa/versionsByAircraftType',
    method: 'GET',
    authRequired: true,
  },
  getLopaDescriptionByFileName: {
    url: 'lopa/{fileName}/description',
    method: 'GET',
    authRequired: true,
  },
  getLopaByTailsign: {
    url: 'lopa?tailsign={tailsign}',
    method: 'GET',
    authRequired: true,
  },
  /**
   * INTEGRATION CENTER ENDPOINTS BELOW
   */
  getConfigurations: {
    url: 'integration-center/configurations?filter={searchTerm}&page={pageNumber}&size={pageSize}&sort=createdAt,DESC',
    method: 'GET',
    authRequired: true,
  },
  getConfigurationEvents: {
    url: 'integration-center/configurations/{configId}/events',
    method: 'GET',
    authRequired: true,
  },
  getItemsForConfiguration: {
    url: 'integration-center/configurations/{configId}/configuration-items',
    method: 'GET',
    authRequired: true,
  },
  createConfiguration: {
    url: 'integration-center/configurations',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  getConfigurationItems: {
    url: 'integration-center/configuration-items?filter={searchTerm}&page={pageNumber}&size={pageSize}&sort=createdAt,DESC',
    method: 'GET',
    authRequired: true,
  },
  getConfigurationItemManifest: {
    url: 'integration-center/configuration-items/{configItemId}/manifest',
    method: 'GET',
    authRequired: true,
  },
  getConfigurationItemTypeDefinition: {
    url: 'integration-center/configuration-items/{configItemId}/type-definition',
    method: 'GET',
    authRequired: true,
  },
  getConfigurationItemEvents: {
    url: 'integration-center/configuration-items/{configItemId}/events',
    method: 'GET',
    authRequired: true,
  },
  getEnvironments: {
    url: 'integration-center/environments?filter={searchTerm}&page={pageNumber}&size={pageSize}&sort=lastUpdatedAt,DESC',
    method: 'GET',
    authRequired: true,
  },
  getEnvironmentEvents: {
    url: 'integration-center/environments/{environmentId}/events',
    method: 'GET',
    authRequired: true,
  },
  createTargeting: {
    url: 'integration-center/targeting/{environmentId}',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  createConfigurationTestResult: {
    url: 'integration-center/targeting/test-results/configurations',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
});

type InitServices = (organizationInfo: { profileOrgId?: string }, keycloak?: KeycloakInstance) => void;

export const initServices: InitServices = async (organizationInfo, keycloak) => {
  const id = axios.interceptors.request.use(async ({ authRequired, ...requestConfig }: AxiosRequestConfig & { authRequired?: boolean }) => {
    const additionalHeaders = {};

    if (authRequired) {
      const token = await getToken(keycloak);
      Object.assign(additionalHeaders, token);
    }

    if (organizationInfo.profileOrgId) {
      Object.assign(additionalHeaders, { 'ControlTower-ProfileId': organizationInfo.profileOrgId });
    }

    return { ...requestConfig, headers: { ...requestConfig.headers, ...additionalHeaders } };
  });

  return () => {
    axios.interceptors.request.eject(id);
  };
};

const services = createServices(serviceConfig);

export default services;
