import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { HARDWARE_DATA_LOCATION } from 'common/environment';
import store from 'state/store';
import { HardwareItem, HardwareItemComponent } from 'modules/fleet-management/state/types/outlineTypes';
import fetchContentFromAzureBlob from 'modules/fleet-management/utils/functions/fetchContentFromAzureDataService';

type RootState = ReturnType<typeof store.getState>;

const hardwareDevicesAdapter = createEntityAdapter<HardwareItem>({
  selectId: (item: HardwareItem) => item.id,
});

export type AircraftHardwareState = {
  items: EntityState<HardwareItem>;
  error?: Error;
};

export const initialState: AircraftHardwareState = {
  items: hardwareDevicesAdapter.getInitialState(),
  error: undefined,
};

export const fetchHardwares = createAsyncThunk<HardwareItem[], string>(
  'fleets/fetchHardwares',
  async (tailsign: string): Promise<HardwareItem[]> => await fetchContentFromAzureBlob(HARDWARE_DATA_LOCATION, tailsign)
);

const hardwareSlice = createSlice({
  name: 'hardware',
  initialState,
  reducers: {
    upsertHardwareItems(state, action) {
      const hardwareItemsWithSortedComponents = sortHardwareItemArrayComponentsByTimestamp(action.payload);
      hardwareDevicesAdapter.upsertMany(state.items, hardwareItemsWithSortedComponents);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchHardwares.pending, (state) => {
        state.error = undefined;
        hardwareDevicesAdapter.removeAll(state.items);
      })
      .addCase(fetchHardwares.rejected, (state, payload) => {
        state.error = payload.error as Error;
        hardwareDevicesAdapter.removeAll(state.items);
      })
      .addCase(fetchHardwares.fulfilled, (state) => {
        state.error = undefined;
      }),
});

export const { upsertHardwareItems } = hardwareSlice.actions;

const hardwareDevicesEntitySelectors = hardwareDevicesAdapter.getSelectors<RootState>((state) => state.hardware.items);

export const selectHardwareDevices = hardwareDevicesEntitySelectors.selectAll;

export const selectHardwareDeviceByItemId = createSelector([selectHardwareDevices, (_, itemId) => itemId], (devices, itemId) =>
  devices.find((device) => device.itemId === itemId)
);

const sortHardwareItemArrayComponentsByTimestamp = (hardwareItems: HardwareItem[]): HardwareItem[] => {
  hardwareItems.forEach((item) => {
    return item?.state?.components.sort((a: HardwareItemComponent, b: HardwareItemComponent) => {
      if (a.timestamp && b.timestamp) {
        return new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf();
      }
      return 1;
    });
  });

  return hardwareItems;
};

export default hardwareSlice.reducer;
