import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchContentDeploymentsByTailsign } from 'modules/fleet-management/state/content/contentDeploymentSlice';
import { fetchLopaByTailsign } from 'modules/fleet-management/state/lopa/lopaSlice';
import { fetchSoftwareAppsAndComponentInstallationsByTailsign } from 'modules/fleet-management/state/software/softwareComponentInstallationSlice';
import { State } from 'state/store';
import {
  ContentDeployment,
  ContentDeploymentLicenseType,
  Filter,
  FilterGroupId,
  ItemHealthStatus,
  SoftwareCertificationType,
  SoftwareComponentInstallation,
} from 'modules/fleet-management/state/types/outlineTypes';
import { MAIN_DECK } from 'modules/fleet-management/view/fleets/aircraft/outline/constants/outlineConstants';

type UpdateFilterStatePayload = {
  active: boolean;
  filterId: number;
  filterLabel?: string;
};

export interface OutlineItemsFilterState {
  itemTypeFilters?: Filter[];
  healthFilters?: Filter[];
  certificationFilters?: Filter[];
  licenseFilters?: Filter[];
  deploymentGroupFilters?: Filter[];
  installationGroupFilters?: Filter[];

  fetchedLopaAndHardwareItemTypeFilters: Filter[];

  fetchedSoftwareComponentInstallationItemTypeFilters: Filter[];
  fetchedSoftwareGroupFilters: Filter[];

  fetchedContentDeploymentItemTypeFilters: Filter[];
  fetchedContentGroupFilters: Filter[];

  activeFilter: FilterGroupId;
}

const initialState: OutlineItemsFilterState = {
  itemTypeFilters: [],
  healthFilters: Object.values(ItemHealthStatus).map((healthStatus, index) => ({ id: index, label: healthStatus, active: false })),
  certificationFilters: Object.values(SoftwareCertificationType).map((certificationType, index) => ({
    id: index,
    label: certificationType,
    active: false,
  })),
  licenseFilters: Object.values(ContentDeploymentLicenseType).map((licenseType, index) => ({
    id: index,
    label: licenseType,
    active: false,
  })),
  deploymentGroupFilters: [],
  installationGroupFilters: [],
  fetchedLopaAndHardwareItemTypeFilters: [],
  fetchedSoftwareComponentInstallationItemTypeFilters: [],
  fetchedSoftwareGroupFilters: [],
  fetchedContentDeploymentItemTypeFilters: [],
  fetchedContentGroupFilters: [],
  activeFilter: FilterGroupId.NO_FILTER,
};

export const filterStatusSlice = createSlice({
  name: 'filterStatusSlice',
  initialState,
  reducers: {
    setActiveFilterId: (state, action: PayloadAction<FilterGroupId>) => {
      let itemTypeFilters: Filter[] = [];
      let installationGroupFilters: Filter[] = [];
      let deploymentGroupFilters: Filter[] = [];

      switch (action.payload) {
        case FilterGroupId.LOPA:
          itemTypeFilters = state.fetchedLopaAndHardwareItemTypeFilters;
          break;
        case FilterGroupId.HARDWARE:
          itemTypeFilters = state.fetchedLopaAndHardwareItemTypeFilters;
          break;
        case FilterGroupId.SOFTWARE:
          itemTypeFilters = state.fetchedSoftwareComponentInstallationItemTypeFilters;
          installationGroupFilters = state.fetchedSoftwareGroupFilters;
          break;
        case FilterGroupId.CONTENT:
          itemTypeFilters = state.fetchedContentDeploymentItemTypeFilters;
          deploymentGroupFilters = state.fetchedContentGroupFilters;
          break;
      }

      return { ...state, activeFilter: action.payload, itemTypeFilters, deploymentGroupFilters, installationGroupFilters };
    },
    deactivateFilters: (state) => {
      state.itemTypeFilters?.forEach((itemTypeFilter) => (itemTypeFilter.active = false));
      state.healthFilters?.forEach((healthFilter) => (healthFilter.active = false));
      state.certificationFilters?.forEach((certificationFilter) => (certificationFilter.active = false));
      state.licenseFilters?.forEach((licenseFilter) => (licenseFilter.active = false));
      state.installationGroupFilters?.forEach((groupFilter) => (groupFilter.active = false));
      state.deploymentGroupFilters?.forEach((groupFilter) => (groupFilter.active = false));
    },
    updateItemFilterActiveState: ({ itemTypeFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(itemTypeFilters, payload);
    },
    updateHealthFilterActiveState: ({ healthFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(healthFilters, payload);
    },
    updateCertificationFilterActiveState: ({ certificationFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(certificationFilters, payload);
    },
    updateLicenseFilterActiveState: ({ licenseFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(licenseFilters, payload);
    },
    updateSoftwareGroupFilterActiveState: ({ installationGroupFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(installationGroupFilters, payload);
    },
    updateContentGroupFilterActiveState: ({ deploymentGroupFilters }, { payload }: PayloadAction<UpdateFilterStatePayload>) => {
      updateFilterActiveState(deploymentGroupFilters, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLopaByTailsign.fulfilled, (state, action) => {
      const areas = action.payload.areas.filter((area) => area.deck.name === MAIN_DECK);
      const areaIdsSet = new Set<number>(areas.map((area) => area.id));
      const itemTypeFilters = action.payload.items
        .filter((item) => areaIdsSet.has(item.areaId))
        .flatMap(({ itemSubType }) => itemSubType)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((type, index) => ({ id: index, label: type, active: false }));

      state.fetchedLopaAndHardwareItemTypeFilters = itemTypeFilters;
    });

    builder.addCase(fetchSoftwareAppsAndComponentInstallationsByTailsign.fulfilled, (state, action) => {
      const softwareItemTypeFilters = action.payload.softwareComponentInstallations
        .flatMap(({ itemSubType }) => itemSubType)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((type, index) => ({ id: index, label: type, active: false }));

      const softwareGroupFilters = action.payload.softwareComponentInstallations
        .flatMap((installation) => (installation as SoftwareComponentInstallation).groups)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((groupName, index) => ({ id: index, label: groupName || '', active: false }));

      state.fetchedSoftwareComponentInstallationItemTypeFilters = softwareItemTypeFilters;
      state.fetchedSoftwareGroupFilters = softwareGroupFilters;
    });

    builder.addCase(fetchContentDeploymentsByTailsign.fulfilled, (state, action) => {
      const contentItemTypeFilters = action.payload
        .flatMap(({ itemSubType }) => itemSubType)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((type, index) => ({ id: index, label: type, active: false }));

      const contentGroupFilters = action.payload
        .flatMap((deployment) => (deployment as ContentDeployment).groups)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((groupName, index) => ({ id: index, label: groupName || '', active: false }));

      state.fetchedContentDeploymentItemTypeFilters = contentItemTypeFilters;
      state.fetchedContentGroupFilters = contentGroupFilters;
    });
  },
});

export const canvasItemsFiltersStatus = (state: State): OutlineItemsFilterState => {
  return state.outlineFilters;
};

export const selectActiveFilterGroupId = ({ outlineFilters }: Pick<State, 'outlineFilters'>): FilterGroupId | null =>
  outlineFilters.activeFilter;

const selectItemTypeFilters = (state: State): Filter[] | undefined => state.outlineFilters.itemTypeFilters;
const selectHealthFilters = (state: State): Filter[] | undefined => state.outlineFilters.healthFilters;
const selectCertificationFilters = (state: State): Filter[] | undefined => state.outlineFilters.certificationFilters;
const selectLicenseFilters = (state: State): Filter[] | undefined => state.outlineFilters.licenseFilters;
const selectInstallationGroupFilters = (state: State): Filter[] | undefined => state.outlineFilters.installationGroupFilters;
const selectDeploymentGroupFilters = (state: State): Filter[] | undefined => state.outlineFilters.deploymentGroupFilters;

export const selectOutlineFilters = createSelector(
  [
    selectItemTypeFilters,
    selectHealthFilters,
    selectCertificationFilters,
    selectLicenseFilters,
    selectInstallationGroupFilters,
    selectDeploymentGroupFilters,
  ],
  (itemTypeFilters, healthFilters, certificationFilters, licenseFilters, installationGroupFilters, deploymentGroupFilters) => ({
    itemTypeFilters: itemTypeFilters || [],
    healthFilters: healthFilters || [],
    certificationFilters: certificationFilters || [],
    licenseFilters: licenseFilters || [],
    softwareGroupFilters: installationGroupFilters || [],
    contentGroupFilters: deploymentGroupFilters || [],
  })
);

export const selectIsGeneralView = (state: State): boolean => !selectActiveFilterGroupId(state);

const updateFilterActiveState = (filters: Filter[] | undefined, { active, filterId }: UpdateFilterStatePayload) => {
  const filter = filters?.find((filter) => filter.id === filterId);
  if (filter) {
    filter.active = active;
  }
};

export default filterStatusSlice.reducer;
