import { ConfigurationItemType } from './ConfigurationItemType';
import { ConfigurationType } from './ConfigurationType';
import { EnvironmentType } from './EnvironmentType';

export const DEFAULT_PAGE_SIZE = 15;

export type SortingType = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export type PaginationType = {
  sort: SortingType;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: false;
};

export type PaginationListType = {
  pageable: PaginationType;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: SortingType;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export type PaginationListResponseType = {
  content: ConfigurationItemType[] | ConfigurationType[] | EnvironmentType[];
  pageable: PaginationType;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: SortingType;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export type PaginatedRequestType = {
  pageable: PaginationType;
  searchTerm: string;
};

export const pagebleInitialState: PaginationType = {
  sort: { empty: false, sorted: false, unsorted: false },
  offset: 0,
  pageNumber: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  paged: true,
  unpaged: false,
};

export const paginationInitialState: PaginationListType = {
  pageable: pagebleInitialState,
  totalPages: 0,
  totalElements: 0,
  last: false,
  size: 0,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true,
  },
  numberOfElements: 0,
  first: true,
  empty: false,
};
